export const productModels = [
  {
    sku: "EM101",
    title: "EM-101",
    serialPart: 101
  },
  {
    sku: "EM102",
    title: "EM-102",
    serialPart: 112
  },
  {
    sku: "EM202",
    title: "EM-202",
    serialPart: 202
  },
  {
    sku: "EM303",
    title: "EM-303",
    serialPart: 303
  },
  {
    sku: "BW02",
    title: "BW-02",
    serialPart: 400
  },
  {
    sku: "BG04",
    title: "BG-04",
    serialPart: 500
  },
  {
    sku: "J01",
    title: "J-01",
    serialPart: 100
  },
  {
    sku: "JIFFYJ2",
    title: "JIFFY J2",
    serialPart: 901
  },
  {
    sku: "JIFFYJ2000",
    title: "JIFFY J2000",
    serialPart: 902
  },
  {
    sku: "JIFFYJ4000",
    title: "JIFFY J4000",
    serialPart: 903
  },
  {
    sku: "EM02",
    title: "EM-02",
    serialPart: 102
  },
  {
    sku: "EM05",
    title: "EM-05",
    serialPart: 105
  },
  {
    sku: "EM505",
    title: "EM-505",
    serialPart: 505
  },
  {
    sku: "G01",
    title: "G-01",
    serialPart: 601
  }
]

export const productWarranty = [
  {
    id: "12MONTHS",
    title: "12 miesięcy",
    badgeColor: "secondary",
    count: 12
  },
  {
    id: "24MONTHS",
    title: "24 miesiące",
    badgeColor: "warning",
    count: 24
  }
]

export const productColors = [
  {
    value: 0,
    name: "Jednolity / brak koloru",
    serialPart: 0
  },
  {
    name: "Srebrny",
    value: 1,
    serialPart: 1
  },
  {
    name: "Czerwony",
    value: 7,
    serialPart: 7
  },
  {
    name: "Fioletowy",
    value: 8,
    serialPart: 8
  },
  {
    name: "Różowy",
    value: 9,
    serialPart: 9
  },
  {
    name: "Biały",
    value: 3,
    serialPart: 3
  },
  {
    name: "Czarny",
    value: 4,
    serialPart: 4
  }
]

export const productBatch = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
export const productWarrantyTimeFormat = "DD/MM/YYYY HH:mm"
