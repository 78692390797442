/* eslint-disable no-console */
import firebase, { db } from "../firebase"
import moment from "moment"
import { toString } from "lodash"
import { repairsStatuses, validateSerialNumber } from "constants/repairs"
import { MongoAPIHost } from "constants/hosts"
import axios from "axios"

import MongoAPI from "./mongoAPI"

// eslint-disable-next-line no-unused-vars
const wait = async time => {
  return new Promise(res => setTimeout(res, time))
}

const collection = db.collection("warranties")
// const getDocument = doc => ({ id: doc.id, ...doc.data() });
const getDocument = async doc => {
  const data = await doc.data()
  return {
    id: doc.id,
    ...data
  }
}

const mapSnapshotToListenedItems = (snapshot, onFetch) => {
  const items = []
  for (let document of snapshot.docs) {
    items.push({
      id: document.id,
      ...document.data()
    })
  }
  onFetch(items)
}

const mapSnapshotToDocs = snapshot => snapshot.docs.map(doc => getDocument(doc))

export const checkCollectionIfIdExists = async (collection, id, returnData = false) => {
  const snapshot = await db.collection(collection).doc(toString(id)).get()
  if (returnData) {
    return snapshot.exists ? snapshot.data() : false
  }
  return snapshot.exists
}

const API = {
  //  will fetch warranties and listen for changes
  fetchWarranties: async (limit, startAt = {}, onFetchSuccess) =>
    await collection
      .orderBy("purchaseDate", "desc")
      .startAfter(startAt)
      .limit(limit)
      .onSnapshot(snapshot => {
        var items = []
        snapshot.forEach(doc => items.push(getDocument(doc)))
        onFetchSuccess(items)
      }),

  fetchReturns: async (limit, startAt = {}, onFetchSuccess) =>
    await db
      .collection("returns")
      .orderBy("createdAt", "desc")
      .startAfter(startAt)
      .limit(limit)
      .onSnapshot(snapshot => {
        var items = []
        snapshot.forEach(doc => items.push(getDocument(doc)))
        onFetchSuccess(items)
      }),
  //  will find warranties
  findWarranties: async findTerm =>
    await collection
      .where("serialNumber", "==", `${findTerm}`)
      .orderBy("purchaseDate", "desc")
      .get()
      .then(mapSnapshotToDocs)
      .catch(err => console.log(err)),

  //  will save new warranty
  saveWarranty: async ({ purchaseDate, serialNumber, warrantyLength }) => {
    const purchaseDateTimestamp = firebase.firestore.Timestamp.fromDate(moment(purchaseDate).toDate())
    const warrantyLengthInt = parseInt(warrantyLength)
    const timeNow = firebase.firestore.Timestamp.now()

    await collection.add({
      purchaseDate: purchaseDateTimestamp,
      serialNumber,
      warrantyLength: warrantyLengthInt,
      createdAt: timeNow
    })

    try {
      await db
        .collection("serialNumbers")
        .doc(serialNumber)
        .set({
          createdAt: timeNow,
          warranty: [
            {
              purchaseDate: purchaseDateTimestamp,
              warrantyLength: warrantyLengthInt
            }
          ]
        })
    } catch (err) {
      console.log("Error while saving serial number", err)
    }
  },

  updateWarranty: async ({ id, purchaseDate, serialNumber, warrantyLength }) =>
    await collection.doc(id).update({
      purchaseDate: firebase.firestore.Timestamp.fromDate(purchaseDate),
      serialNumber,
      warrantyLength: parseInt(warrantyLength),
      updatedAt: firebase.firestore.Timestamp.now()
    }),

  addEventToSerialNumber: async ({ serialNumber, eventData }) => {
    try {
      await db.collection("serialNumbers").doc(serialNumber)
    } catch (err) {
      console.log("Error while fetching serial number", err)
    }
  },

  fetchServiceParts: (onFetch = () => {}) =>
    db
      .collection("serviceParts")
      .orderBy("partCode")
      .onSnapshot(snapshot => mapSnapshotToListenedItems(snapshot, onFetch)),
  // .get()
  // .then(querySnapshot => {
  //   const data = querySnapshot.docs.map(doc => {
  //     const docData = doc.data()
  //     return {
  //       id: doc.id,
  //       ...docData
  //     }
  //   })
  //   return data
  // })
  saveServicePart: async (name, code, count, author = null) => {
    const timeNow = firebase.firestore.Timestamp.now()
    const partCount = parseInt(count)
    const partCode = parseInt(code)

    await db
      .collection("serviceParts")
      .doc("" + partCode)
      .set({
        createdAt: timeNow,
        createdBy: author,
        partName: name,
        partCode: partCode,
        partCount: partCount,
        partCountHistory: [
          {
            count: partCount,
            date: timeNow,
            author
          }
        ]
      })
      .catch(err => err)

    return true
  },

  updateServicePart: async (code, name, count, author = null) => {
    const timeNow = firebase.firestore.Timestamp.now()
    const partCode = code.toString()
    const partCount = parseInt(count)
    const docRef = await db.collection("serviceParts").doc(partCode)

    if (docRef.id !== undefined) {
      // const docData = await docRef.get().then(doc => getDocument(doc))

      docRef
        .update({
          partCount: partCount,
          partName: name,
          // partCountHistory: [
          //   ...docData.partCountHistory,
          //   {
          //     count: partCount,
          //     date: timeNow,
          //     author
          //   }
          // ],
          updatedAt: timeNow,
          updatedBy: author
        })
        .then(() => console.log("Document successfully updated!"))
        .catch(err => {
          console.log("There was an error while updating the part", err)
          return false
        })
    }

    return true
  },

  checkServicePartIdIfExists: async (id, withData = false) =>
    await checkCollectionIfIdExists("serviceParts", id, withData),

  checkForRepairIdIfExists: async (id, withData = false) => await checkCollectionIfIdExists("repairs", id, withData),

  addServicePartHistory: async (code, name, type, count, author = null, oldCount = 0) => {
    const timeNow = firebase.firestore.Timestamp.now()
    const partCount = parseInt(count)
    const partCode = parseInt(code)
    const oldPartsCount = parseInt(oldCount)

    let insertedData = {
      createdAt: timeNow,
      createdBy: author,
      historyType: type,
      partCode: partCode,
      partName: name,
      partCount: partCount,
      previousCount: oldPartsCount
    }

    await db
      .collection("servicePartsHistory")
      .add(insertedData)
      .catch(err => err)

    try {
      await axios
        .post(`${MongoAPIHost}/savePartHistory`, insertedData)
        .catch(err => console.log("Error while sending data to mongo", err))
    } catch (err) {
      console.log("Error while sending data to mongo", err)
    }

    return true
  },

  fetchServicePartsHistory: async (onFetch = () => {}) =>
    db
      .collection("servicePartsHistory")
      .orderBy("createdAt", "desc")
      .limit(100)
      .onSnapshot(async snapshot => mapSnapshotToListenedItems(snapshot, onFetch)),

  fetchServicePartsUsageHistory: async (start, end) =>
    await axios
      .post(`${MongoAPIHost}/countPartHistory`, {
        start: start,
        end: end
      })
      .then(response => response.data)
      .catch(err => console.log("Could not fetch parts history count")),

  fetchRepairs: async (onFetch = () => {}) => {
    const startDate = moment().subtract(5, "months").toDate()
    const firebaseStartDate = firebase.firestore.Timestamp.fromDate(startDate)

    await db
      .collection("repairs")
      .where("createdAt", ">=", firebaseStartDate)
      .orderBy("createdAt", "desc")
      .onSnapshot(snapshot => mapSnapshotToListenedItems(snapshot, onFetch))
  },

  fetchRepairsForSerialNumber: async (serialNumber, onFetch = () => {}) => {
    const ean = validateSerialNumber(serialNumber)
    await db
      .collection("repairs")
      .where("serial", "in", [serialNumber.toString(), validateSerialNumber(serialNumber).toString()])
      .orderBy("createdAt", "asc")
      .onSnapshot(snapshot => mapSnapshotToListenedItems(snapshot, onFetch))
  },

  updateRepair: async (id, status = null, author = null, serial = null, callback = () => {}) => {
    const timeNow = firebase.firestore.Timestamp.now()
    const repairId = id.toString()
    const docRef = await db.collection("repairs").doc(repairId)
    let updateObject = {}

    if (docRef.id !== undefined) {
      // const docData = await docRef.get().then(doc => getDocument(doc))
      if (status === repairsStatuses.DONE) {
        updateObject = {
          doneAt: timeNow,
          doneBy: author,
          status: status
        }
      }
      if (status === repairsStatuses.IN_PROGRESS) {
        updateObject = {
          updatedAt: timeNow,
          updatedBy: author,
          status: status
        }
      }

      if (status === repairsStatuses.SENT) {
        updateObject = {
          sentAt: timeNow,
          updatedBy: author,
          status: status
        }
      }

      if (status === repairsStatuses.UTILIZATION) {
        updateObject = {
          doneAt: timeNow
        }
      }

      if (serial !== null) {
        updateObject = {
          serial: serial
        }
      }

      if (status !== null) {
        updateObject = {
          ...updateObject,
          status: status
        }
      }

      // await wait(3000)

      await docRef
        .update(updateObject)
        .then(() => {
          console.log("Document successfully updated!")
          callback()
        })
        .catch(err => {
          console.log("There was an error while updating the part", err)
          return false
        })
    }

    return true
  },

  saveRepair: async (id = null, serial, author = null) => {
    const timeNow = firebase.firestore.Timestamp.now()
    let repairId = null

    if (id === null) {
      console.log("Fetching new repair id ...")
      const newRepairId = await MongoAPI.fetchNextRepairId()
      console.log("Got new repair Id", newRepairId)
      repairId = newRepairId.toString()
    } else {
      repairId = id.toString()
    }

    const repairData = {
      createdAt: timeNow,
      createdBy: author,
      serial: serial,
      status: "NEW"
    }

    return await db
      .collection("repairs")
      .doc(repairId)
      .set(repairData)
      .then(() => console.log("Repair successfully saved!"))
      .then(() => MongoAPI.saveRepair(null, repairData))
      .then(() => console.log("Saved repair in MongoDB"))
      .catch(error => {
        console.log("There was an error while updating the part", error)
        return false
      })
  },

  fetchRepairByRepairId: async id => {
    const repairId = id.toString()

    return await db
      .collection("repairs")
      .where("serial", "==", repairId)
      .orderBy("createdAt", "desc")
      .get()
      .then(mapSnapshotToDocs)
      .catch(error => {
        console.log("There was an error while updating the part", error)
        return false
      })
  },

  fetchRepairByRepairId2: async id => {
    const repairId = id.toString()

    return await db
      .collection("repairs")
      .doc(repairId)
      .get()
      .then(async doc => {
        const data = await doc.data()
        return {
          id: doc.id,
          ...data
        }
      })
      .catch(error => {
        console.log("There was an error while updating the part", error)
        return false
      })
  }
}

export default API
