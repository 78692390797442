import React, { useEffect, useState } from "react"
import { ModalFooter, Button, Tooltip } from "reactstrap"
import useAuthorContext from "hooks/useAuthorContext"

import "./style.scss"

const ServicePartsModalFooter = () => {
  const [author] = useAuthorContext()

  const [enableTooltip, setEnableTooltip] = useState(false)

  useEffect(() => {
    setEnableTooltip(true)

    return () => {
      setEnableTooltip(false)
    }
  }, [])

  return (
    <ModalFooter className="scanner-footer">
      <span>
        Skanujesz jako: <strong>{author ? author : <code>Brak</code>}</strong>
      </span>
      <div>
        {enableTooltip && (
          <Tooltip placement="left" target="saveButton" fade={false} isOpen={author === false} offset={[0, 5]}>
            Najpierw wybierz osobe skanującą
          </Tooltip>
        )}
        <Button id="saveButton" color="primary" type="submit" disabled={author === false}>
          Zapisz
        </Button>
      </div>
    </ModalFooter>
  )
}

export default ServicePartsModalFooter
