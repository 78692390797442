import React, { useEffect, useRef } from "react"
import {
  Modal as RModal,
  ModalHeader
  // Input
} from "reactstrap"

const Modal = ({ children, isOpen, toggle, title = "", size = "lg", ...props }) => {
  const modalWrapperRef = useRef(null)

  useEffect(() => {
    if (!isOpen) {
      return
    }
  }, [isOpen])

  return (
    <RModal isOpen={isOpen} keyboard={true} fade={false} size={size} ref={modalWrapperRef} {...props}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      {children}
    </RModal>
  )
}

export default Modal
