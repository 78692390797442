// import { legacy_createStore as createStore, applyMiddleware } from "redux"
import { configureStore } from "@reduxjs/toolkit"
// import { configureStore } from "@reduxjs/toolkit"

import { thunk } from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import rootReducer from "./reducers"
import apiMiddleware from "./middleware/apiMiddleware"
import printerMiddleware from "./middleware/printerMiddleware"
import logger from "./middleware/logger"

// const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || composeWithDevTools

const middlewareList = [apiMiddleware, printerMiddleware /*, logger*/]

const middlewares = getDefaultMiddleware => {
  let middlewareList = [thunk, apiMiddleware, printerMiddleware]

  if (process.env.NODE_ENV !== "production") {
    // middlewareList = [...middlewareList, logger]
  }

  return middlewareList
}

// const store = createStore(rootReducer, initialState, storeEnhancers(applyMiddleware(...middlewareList)))

const store = configureStore({
  reducer: rootReducer,
  middleware: middlewares,
  devTools: process.env.NODE_ENV !== "production"
})

export default store
