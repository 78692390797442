import { useState, useEffect, useCallback } from "react"
import { Button, ModalBody, ModalFooter, Form, Alert } from "reactstrap"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

import FormRow from "components/FormRow"
import EmptyFormRow from "components/EmptyFormRow"
import Modal from "components/EmptyModal"

import { repairsStatuses, repairsStatusesName } from "constants/repairs"

import API from "API"

// import useAuthorContext from "hooks/useAuthorContext"
import useScannerContext from "hooks/useScannerContext"

const RepairsModal = ({ isOpen, toggle, onSave = () => {}, repairId = null, closeModalCallback }) => {
  const [isError, setIsError] = useState(false)
  const [state] = useScannerContext()
  const [repairStatus, setRepairStatus] = useState(null)
  const isEditMode = repairId !== null

  //  schema
  const schema = yup.object().shape({
    id: yup
      .number()
      .positive()
      // .required("Numer naprawy jest wymagany")
      .typeError("Numer naprawy jest niepoprawny"),
    // .test("id-exists", "Ten numer naprawy już istnieje", async value => {
    //   if (value < 100 || isEditMode) return true
    //   const idExists = await API.checkForRepairIdIfExists(value)
    //   return idExists ? false : true
    // })
    serial: yup.string().required("Proszę wpisac numer seryjny").typeError("Numer seryjny jest niepoprawny"),
    // .test("id-exists", "Ten kod juz istnieje, proszę podać inny"),
    author: isEditMode ? yup.string() : yup.string().required("Proszę wpisac osobę przyjmującą")
  })

  //  hook-form
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    // getValues,
    reset
  } = useForm({
    defaultValues: {
      id: "",
      serial: "",
      author: ""
    },
    resolver: yupResolver(schema),
    shouldUnregister: true
  })

  const fetchRepairWithId = useCallback(
    repairId => {
      const row = state.repairs.find(repair => repair.id === repairId)

      if (row !== undefined) {
        setValue("id", row.id)
        setValue("serial", row.serial)
        // setValue("author", row.createdBy)
        // setValue("")

        setRepairStatus(row.status)

        toggle(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.repairs]
  )

  const rollbackRepairStatus = useCallback(
    async (repairId, newStatus = repairsStatuses.IN_PROGRESS) => {
      const row = state.repairs.find(repair => repair.id === repairId)

      if (row !== undefined) {
        await API.updateRepair(row.id, newStatus, "EditRepairFormRollback", row.serial).then(() => {
          reset()
          toggle()
        })
      }
    },
    [reset, state.repairs, toggle]
  )

  const onSubmit = async data => {
    const { id = null, serial, author = null } = data

    if (isEditMode) {
      await API.updateRepair(id, null, null, serial)
        .then(() => {
          reset() //  reset form
          onSave() // fire onSave to reload data
          toggle() //  close modal
        })
        .catch(err => {
          console.log(err)
          setIsError("Wystąpił błąd, spróbuj ponownie")
        })
    } else {
      await API.saveRepair(id, serial, author)
        .then(() => {
          reset() //  reset form
          onSave() // fire onSave to reload data
          toggle() //  close modal
        })
        .catch(err => {
          console.log(err)
          setIsError("Wystąpił błąd, spróbuj ponownie")
        })
    }
  }

  useEffect(() => {
    if (repairId !== null) {
      fetchRepairWithId(repairId)
    } else {
      setIsError(false)
    }
  }, [fetchRepairWithId, repairId])

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title={isEditMode ? "Edytuj naprawę" : "Nowa naprawa"}
      size="xl"
      autoFocus={true}
      onClosed={closeModalCallback}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        {isError && <Alert color="danger">{isError}</Alert>}
        <ModalBody>
          {isEditMode && (
            <FormRow
              labelSize={3}
              inputSize={9}
              type="number"
              id="id"
              title="Numer naprawy"
              placeholder="numer naprawy"
              control={control}
              errors={errors}
              prepend="R"
              readOnly={true}
            />
          )}

          <FormRow
            labelSize={3}
            inputSize={9}
            id="serial"
            title="Numer seryjny"
            placeholder="numer seryjny"
            control={control}
            errors={errors}
          />
          {!isEditMode && (
            <FormRow
              labelSize={3}
              inputSize={9}
              id="author"
              title="Przyjmujący"
              placeholder="osoba przyjmująca"
              control={control}
              errors={errors}
            />
          )}
          {isEditMode === true && repairStatus === repairsStatuses.DONE && (
            <EmptyFormRow title="Status naprawy">
              <Button
                id="setStatusInProgress"
                color="secondary"
                type="button"
                onClick={() => rollbackRepairStatus(repairId)}
              >{`Zmień status na "${repairsStatusesName.IN_PROGRESS}"`}</Button>
            </EmptyFormRow>
          )}
          {isEditMode === true && repairStatus === repairsStatuses.UTILIZATION && (
            <EmptyFormRow title="Status naprawy">
              <Button
                id="setStatusInProgress"
                color="secondary"
                type="button"
                onClick={() => rollbackRepairStatus(repairId, repairsStatuses.DONE)}
              >{`Zmień status na "${repairsStatusesName.DONE}"`}</Button>
            </EmptyFormRow>
          )}
        </ModalBody>
        <ModalFooter>
          <Button id="saveButton" color="primary" type="submit">
            Zapisz
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default RepairsModal
